import React from 'react'
import GalleryComponent from '../01 Atoms/galleryComponent'

export default ( slice ) =>
<>
  <div className="wrapper">
    <div className="content-blocks content-blocks--gallery">
      <div className={"content-blocks__items--grid content-blocks__items--gallery"}>
          <GalleryComponent data={slice.slices} />
      </div>
    </div>
  </div>
</>