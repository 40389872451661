import React, { useState } from "react";
import ThumbGrid from "./thumbnails";
import LightBox from "./lightbox";

const GalleryComponent = props => {

    const [showLightbox, setShowLightbox] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)

    const handleOpen = i => e => {
        setShowLightbox(true)
        setSelectedImage(i)
    }
    const handleClose = () => {
        setShowLightbox(false)
        setSelectedImage(null)
    }
    const handlePrevRequest = (i, length) => e => {
        setSelectedImage((i - 1 + length) % length)
    }
    const handleNextRequest = (i, length) => e => {
        setSelectedImage((i + 1) % length)
    }

    const images = props.data;

    return (
        <>
        <ThumbGrid images={images} handleOpen={handleOpen} />
        {showLightbox && selectedImage !== null && (
            <LightBox
            images={images}
            handleClose={handleClose}
            handleNextRequest={handleNextRequest}
            handlePrevRequest={handlePrevRequest}
            selectedImage={selectedImage}
            />
        )}
        </>
    )
}

export default GalleryComponent