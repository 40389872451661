import React from 'react'
import { RichText } from 'prismic-reactjs'
import { graphql } from 'gatsby';
import GalleryFeedSlice from '../03 Organisms/GalleryFeed';
import Layout from '../04 Templates/layout'

export const query = graphql`
query GalleryPageQuery($uid: String!) {  
    prismic {
      allGallerys(uid: $uid) {
        edges {
          node { 
            _meta {
              uid
              type
              tags
            }
            hero_image
            hero_imageSharp {
              childImageSharp {
                fluid(maxWidth: 2100) {
                    ...GatsbyImageSharpFluid
                }
              }
            }            
            title
            intro
            body {
              ... on PRISMIC_GalleryBodyGallery_image {
                type
                fields {
                    insta {
                      ... on PRISMIC__ExternalLink {
                        _linkType
                        url
                      }
                      ... on PRISMIC_Landing_page {
                        title
                        introduction
                        _meta {
                          uid
                          type
                        }
                      }
                      ... on PRISMIC_Destination {
                        short_name
                        hero_image
                        _meta {
                          uid
                          type
                        }
                      }
                      ... on PRISMIC_Inspiration {
                        numeral
                        title
                        _meta {
                          uid
                          type
                        }
                      }
                      ... on PRISMIC_News_content {
                        title
                        introduction
                        _meta {
                          uid
                          type
                        }
                      }
                      ... on PRISMIC_Content {
                        title
                        introduction
                        _meta {
                          uid
                          type
                        }
                      }
                    }                             
                    caption
                    image
                    imageSharp {
                        childImageSharp {
                            fluid(maxWidth: 600) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }                       
                }
              }
            }
          }
        }
      }
    } 
}
`

const RenderSlices = ({ slices }) => {
    return slices.map((slice, index) => {
      const res = (() => {
        switch(slice.type) {

          case 'gallery_image': return (
  
            <section key={index} className="homepage-slice-wrapper">
              <GalleryFeedSlice slices={slice.fields} />
            </section>

          )

          default: return

        }
      }
      )();
      return res;
    })
  }

  const RenderIndexBody = ({ home, slices, content }) => (
    <React.Fragment>
        {home.content ? 
        <>
        <section className="destination--content">
          <div className="wrapper">
            <div className="content--intro">
              {RichText.render(home.content)}
            </div>
          </div>
        </section>
        <div className="horizontal-rule"></div>
        </>
        : "" }
        <RenderSlices slices={home.body} />
    </React.Fragment>
  );
  
  const Destination = props => {
    const doc = props.data.prismic.allGallerys.edges.slice(0,1).pop();
    if(!doc) return null;
    return(
      <Layout data={doc}>
        <RenderIndexBody home={doc.node} />
      </Layout>
    )
  }
  
  export default Destination;